import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useMsalAuthentication } from "@azure/msal-react";
import { InteractionType, InteractionStatus } from '@azure/msal-browser';
import { jwtDecode } from 'jwt-decode';
import { Alert, AppLayout, Button } from '@cloudscape-design/components';
import { I18nProvider } from '@cloudscape-design/components/i18n';
import messages from '@cloudscape-design/components/i18n/messages/all.en';
import TopNav from './components/TopNav';
import Breadcrumbs from './components/Breadcrumbs';
import SideNav from './components/SideNav';
import FlashNotification from './components/FlashNotification';
import HelpInfo from './components/HelpInfo';
import HomePage from './pages/Home';
import SiteConfigPage from './pages/SiteConfig';
import FeedbackPage from './pages/Feedback';

const LOCALE = 'en';

const App = () => {
  const adminGroup = process.env.REACT_APP_ADMIN_GROUP;
  
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [userFullName, setUserFullName] = useState(null);
  const [idToken, setIdToken] = useState(null);

  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState("info");
  const [sideNavigationOpen, setSideNavigationOpen] = useState(true);
  const [toolsOpen, setToolsOpen] = useState(true);
  const [toolsInformation, setToolsInformation] = useState(null);

  const { login } = useMsalAuthentication(InteractionType.Redirect);
  const { instance, accounts, inProgress } = useMsal();

  useEffect(() => {
    if (accounts.length > 0 && inProgress === InteractionStatus.None) {
      const idTokenClaims = "idTokenClaims" in accounts[0] ? accounts[0].idTokenClaims : jwtDecode(accounts[0].idToken);
      const tokenUsername = idTokenClaims.name;
      const tokenGroupMembership = idTokenClaims.groups;

      const isMemberOfAdminGroup = tokenGroupMembership.includes(adminGroup);

      setIsAuthorized(isMemberOfAdminGroup);
      setUserFullName(tokenUsername);
      setIdToken(accounts[0].idToken);
    };
  }, [accounts, inProgress]);

  return (
    <React.Fragment>
      <AuthenticatedTemplate>
        { isAuthorized &&
          <I18nProvider locale={LOCALE} messages={[messages]}>
            <TopNav 
              fullName={userFullName}
            />
            <AppLayout
              breadcrumbs={<Breadcrumbs />}
              navigationOpen={sideNavigationOpen}
              navigation={<SideNav />}
              onNavigationChange={() => { setSideNavigationOpen(!sideNavigationOpen); }}
              notifications={
                alertMessage &&
                <FlashNotification 
                  alertType={alertType}  
                  alertMessage={alertMessage}
                  setAlertMessage={setAlertMessage}
                />
              }
              toolsOpen={toolsOpen}
              tools={<HelpInfo toolsInformation={toolsInformation} />}
              onToolsChange={() => { setToolsOpen(!toolsOpen); }}
              content={
                <Routes>
                  <Route 
                    path="/" 
                    element={
                      <HomePage
                        idToken={idToken}
                        setAlertMessage={setAlertMessage}
                        setAlertType={setAlertType}
                        setToolsInformation={setToolsInformation} 
                      />
                    } 
                  />
                  <Route 
                    path="/config" 
                    element={
                      <SiteConfigPage
                        idToken={idToken}
                        setAlertMessage={setAlertMessage}
                        setAlertType={setAlertType}
                        setToolsInformation={setToolsInformation}
                      />
                    } 
                  />
                  <Route 
                    path="/feedback"
                    element={
                      <FeedbackPage 
                        idToken={idToken}
                        setAlertMessage={setAlertMessage} 
                        setAlertType={setAlertType}
                        setToolsInformation={setToolsInformation} 
                      />
                    }
                  />
                </Routes>
              }
            />
          </I18nProvider>
        }
        {!isAuthorized &&
          <Alert statusIconAriaLabel="Error" type="error" header="Access Denied">
            Sorry, you're not authorized to access this page. If you believe this is an error, please contact your admin.
          </Alert>
        }
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Alert statusIconAriaLabel="Info" header="Log in please" action={<Button onClick={() => login(InteractionType.Popup)}>Log in</Button>}>
          Please log in to access this page.
        </Alert>
      </UnauthenticatedTemplate>
    </React.Fragment>
  );
};

export default App;
