import React from 'react';
import SideNavigation from '@cloudscape-design/components/side-navigation';

const SideNav = () => {
  const basePath = process.env.REACT_APP_ADMIN_BASE_PATH;

  return (
    <SideNavigation
        header={{ text: 'Admin Panel' }}
        items={[
            { type: 'link', text: `Home`, href: `${basePath}/` },
            { type: 'link', text: `Site Configuration`, href: `${basePath}/config` },
            { type: 'link', text: `Feedback`, href: `${basePath}/feedback` },
            { type: "divider" },
            { type: 'link', text: `Source`, href: `https://origin.sharepoint.com/`, external: true },
            { type: 'link', text: `Documentation`, href: `https://originenergy.atlassian.net/wiki/spaces/GENAI/pages/6311477250/Origin+Talk`, external: true },
            { type: 'link', text: `Github Code Repo`, href: `https://github.com/originenergy-au/origin-talk`, external: true },
        ]}
    />
  );
};

export default SideNav;