// Copyright © Amazon.com and Affiliates: This deliverable is considered
// Developed Content as defined in the AWS Service Terms and the MOU between
// the parties dated July 2024.

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import { msalInstance } from './scripts/AuthConfig';
import App from './App';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('origintalkadmin_root'));
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Router basename={process.env.REACT_APP_ADMIN_BASE_PATH}>
        <App />
      </Router>
    </MsalProvider>
  </React.StrictMode>
);