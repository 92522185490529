import React from 'react';
import Flashbar from '@cloudscape-design/components/flashbar';

const FlashNotification = ({ alertType, alertMessage, setAlertMessage }) => {
    return (
        <Flashbar
            items={[
                {
                    type: alertType,
                    content: alertMessage,
                    dismissible: true,
                    onDismiss: () => {setAlertMessage(null)}
                }
            ]}
        />
    );
};

export default FlashNotification;