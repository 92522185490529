import React from 'react';
import TopNavigation from "@cloudscape-design/components/top-navigation";

const TopNav = ({ fullName }) => {
  const basePath = process.env.REACT_APP_ADMIN_BASE_PATH;
  
  return (
    <TopNavigation
      identity={{
        href: `${basePath}/`,
        title: "OriginTalk",
        logo: {
          src: `${basePath}/assets/origin-talk-avitar-50px.svg`,
          alt: "OriginTalk"
        }
      }}
      utilities={[
        {
          type: "button",
          text: `Welcome ${fullName}`,
          iconName: "user-profile"
        }
      ]}
    />
  );
};

export default TopNav;