import { PublicClientApplication } from '@azure/msal-browser';

const base_path = process.env.REACT_APP_ADMIN_BASE_PATH;

const redirect_uri =  `${window.origin}${base_path}/`;

export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_ADMIN_AZURE_CLIENT_ID,
        authority: `https://login.microsoftonline.com/${process.env.REACT_APP_ADMIN_AZURE_TENANT_ID}`,
        redirectUri: redirect_uri
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
    },
};

export const msalInstance = new PublicClientApplication(msalConfig);