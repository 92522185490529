import React from 'react';
import SegmentedControl from '@cloudscape-design/components/segmented-control';

const FeedbackNav = ({ selectedFeedbackNav, setSelectedFeedbackNav }) => {
    return (
        <div>
            <SegmentedControl
                selectedId={selectedFeedbackNav}
                onChange={({ detail }) =>
                    setSelectedFeedbackNav(detail.selectedId)
                }
                options={[
                    { id: "ALL", text: "All"  },
                    { id: "USEFUL", text: "Thumb Up"  },
                    { id: "NOT_USEFUL", text: "Thumb Down" }
                ]}
            />
        </div>
    )
};

export default FeedbackNav;