import axios from 'axios';

const apiEndpoint = `${process.env.REACT_APP_ADMIN_BACKEND_API}/feedback`;

const FetchFeedback = async (idToken, setAlertType, setAlertMessage, setLoading, setAllItems, setStartKey, allItems=[], startKey=null) => {
    setLoading(true);

    try {
        let parameters = { token: idToken };
        if (startKey && startKey.length > 1) {
            parameters.start_key = startKey;
        }

        const response = await axios.get(apiEndpoint, {
            params: parameters
        });
        
        const items = response.data.Items.map(item => ({
            ...item,
            message: decodeURIComponent(item.message),
            prevMessage: decodeURIComponent(item.prevMessage),
            timestamp: parseInt(item.timestamp, 10)
        }));

        items.sort((a, b) => b.timestamp - a.timestamp);

        if (startKey && startKey.length > 1) {
            setAllItems([...allItems, ...items]);
        }
        else {
            setAllItems(items);
        }
        setStartKey(response.data.LastEvaluatedKey);
    } catch (err) {
        setAlertType("error");
        setAlertMessage(`Unable to fetch feedback: ${err}`);
    } finally {
        setLoading(false);
    }
}

export default FetchFeedback;