import React, { useEffect, useState } from "react";
import { useCollection } from '@cloudscape-design/collection-hooks';
import {
  Box,
  Button,
  ColumnLayout,
  CollectionPreferences,
  ContentLayout,
  Header,
  Pagination,
  SpaceBetween,
  Table,
  TextFilter,
} from '@cloudscape-design/components';
import { 
  columnDefinitions, 
  getMatchesCountText, 
  paginationLabels, 
  collectionPreferencesProps 
} from '../scripts/FeedbackTableConfig';
import FeedbackNav from '../components/FeedbackNav';
import exportToCSV from '../scripts/ExportToCsv';
import EmptyState from "../scripts/TableEmptyState";
import FetchFeedback from "../scripts/FetchFeedback";

// Main component
const FeedbackPage = ({ idToken, setAlertType, setAlertMessage, setToolsInformation }) => {
  const [allItems, setAllItems] = useState([]);
  const [itemsLoading, setItemsLoading] = useState(true);
  const [moreItemsLoading, setMoreItemsLoading] = useState(false);
  const [startKey, setStartKey] = useState(null);
  const [selectedFeedbackNav, setSelectedFeedbackNav] = useState("ALL");
  const [filteredItems, setFilteredItems] = useState(allItems);
  
  useEffect(() => {
    // Set information content for this page
    setToolsInformation("<p>This page displays user feedback gathered through 'thumb up' and 'thumb down' buttons.</p> \
      <h4>Default Sorting</h4> \
      <p>By default, feedback is sorted by date, with the most recent entries appearing first. The page loads 100 feedback items at a time. If additional feedback is available, a \"Load More\" button will appear to load older entries.</p> \
      <h4>Messages</h4> \
      <p>The \"Q Message\" column shows the response from Q Business that received the feedback. The \"Previous Message\" column displays the preceding message to provide better context. Click on these message snippets to view the full text.</p> \
      <h4>Filtering Results</h4> \
      <p>You can filter feedback by \"Thumb up\" or \"Thumb down\" and search for specific text using the search bar.</p> \
      <h4>Export Results</h4> \
      <p>To export the current filtered results to a CSV file, click the \"Export to CSV\" button. To export all feedback, ensure that all filters are removed before exporting.</p>");
    
    // Fetch feedback from DynamoDB
    FetchFeedback(idToken, setAlertType, setAlertMessage, setItemsLoading, setAllItems, setStartKey);
  }, []);

  // Fetch more feedback if initial fetch exceeded the total allowed items
  const fetchMoreItems = () => {
    if (startKey && startKey.length > 1) {
      FetchFeedback(idToken, setAlertType, setAlertMessage, setMoreItemsLoading, setAllItems, setStartKey, allItems, startKey);
    }
  };

  // Filter feedback based on selected thumb navigation
  useEffect(() => {
    if (selectedFeedbackNav === "ALL") {
      setFilteredItems(allItems);
    } 
    else 
    {
      setFilteredItems(allItems.filter((item) =>
        item.usefulness === selectedFeedbackNav
      ));
    }
  }, [allItems, selectedFeedbackNav]);

  // Define table preference
  const [preferences, setPreferences] = useState({
      pageSize: 10,
      contentDisplay: [
        { id: 'usefulness', visible: true },
        { id: 'reporter', visible: true },
        { id: 'comment', visible: true },
        { id: 'reason', visible: true },
        { id: 'timestamp', visible: true },
        { id: 'message', visible: true },
        { id: 'prevMessage', visible: true },
      ],
  });

  // Define table hook
  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
      filteredItems,
      {
        filtering: {
          empty: <EmptyState title="Nothing found" />,
          noMatch: (
            <EmptyState
              title="No matches"
              action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
            />
          ),
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: {},
        selection: {},
      }
  );

  return (
    <ContentLayout header={<Header variant="h1">Feedback</Header>}>
      <SpaceBetween size="l">
        <ColumnLayout columns={2}>
          <FeedbackNav 
            selectedFeedbackNav={selectedFeedbackNav}
            setSelectedFeedbackNav={setSelectedFeedbackNav}
          />
          <div style={{"textAlign": "right"}}><Button onClick={() => exportToCSV(items)}>Export to CSV</Button></div>
        </ColumnLayout>
        <Table
          {...collectionProps}
          header={<Header counter={`(${items.length})`}>Feedback</Header>}
          columnDefinitions={columnDefinitions}
          columnDisplay={preferences.contentDisplay}
          items={items}
          pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
          filter={
            <TextFilter
              {...filterProps}
              countText={getMatchesCountText(filteredItemsCount)}
              filteringAriaLabel="Filter instances"
            />
          }
          preferences={
            <CollectionPreferences
              {...collectionPreferencesProps}
              preferences={preferences}
              onConfirm={({ detail }) => setPreferences(detail)}
            />
          }
          loading={itemsLoading ? true : false}
          loadingText="Loading Data"
          resizableColumns
          stickyHeader
          empty={
            <Box
            margin={{ vertical: "xs" }}
            textAlign="center"
            color="inherit"
            >
              <b>Nothing found</b>
            </Box>
          }
          footer={ (startKey && startKey.length > 1) ?
            <Box textAlign="center">
              <Button loading={moreItemsLoading ? true : false} onClick={fetchMoreItems}>Load more</Button>
            </Box>
            :
            null
          }
        />
      </SpaceBetween>
    </ContentLayout>
  );
};

export default FeedbackPage;