import React from 'react';
import HelpPanel from '@cloudscape-design/components/help-panel';

const HelpInfo = ({ toolsInformation }) => {
    return (
        <HelpPanel
            header={
                <h2>
                    Information
                </h2>
            }
        >
            <div
            dangerouslySetInnerHTML={{__html: toolsInformation}}
            />
        </HelpPanel>
    );
}

export default HelpInfo;