import * as React from 'react';
import Icon from "@cloudscape-design/components/icon";
import Popover from "@cloudscape-design/components/popover";

export function getMatchesCountText(count) {
  return count === 1 ? `1 match` : `${count} matches`;
}

function formatTimestamp(timestamp) {
  const date = new Date(timestamp);
  const pad = (number) => number.toString().padStart(2, '0');

  const getMonthName = (monthIndex) => {
      const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      return months[monthIndex];
  };

  const day = pad(date.getDate());
  const month = getMonthName(date.getMonth());
  const year = date.getFullYear();
  let hours = date.getHours();
  const minutes = pad(date.getMinutes());

  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? pad(hours) : '12'; // '0' should be '12'

  return `${day} ${month} ${year} ${hours}:${minutes}${ampm}`;
}

function createLabelFunction(columnName) {
  return ({ sorted, descending }) => {
    const sortState = sorted ? `sorted ${descending ? 'descending' : 'ascending'}` : 'not sorted';
    return `${columnName}, ${sortState}.`;
  };
}

function getThumbIcon(type) {
  switch (type) {
    case 'USEFUL':
      return <Icon name="thumbs-up" />;
    case 'NOT_USEFUL':
      return <Icon name="thumbs-down" />;
    default:
      return null;
  }
}

function processMessage(message) {

  const trimmedMessage = message.length > 50 ? message.substring(0, 50) + "..." : message;
  return <Popover
            dismissButton={true}
            position="right"
            size="large"
            triggerType="custom"
            content={message}
          >
            {trimmedMessage}
          </Popover>
}

export const columnDefinitions = [
  {
    id: 'usefulness',
    header: 'Type',
    cell: e => getThumbIcon(e.usefulness),
    ariaLabel: createLabelFunction('usefulness'),
    sortingField: 'usefulness',
    width: 50,
    minWidth: 50
  },
  {
    id: 'reporter',
    header: 'Reporter',
    cell: e => e.reporter,
    ariaLabel: createLabelFunction('reporter'),
    sortingField: 'reporter',
    width: 200,
    minWidth: 170
  },
  {
    id: 'comment',
    header: 'Comment',
    cell: e => decodeURI(e.comment),
    ariaLabel: createLabelFunction('comment'),
    sortingField: 'comment',
    width: 200,
    minWidth: 170
  },
  {
    id: 'reason',
    header: 'Reason',
    cell: e => e.reason,
    ariaLabel: createLabelFunction('reason'),
    sortingField: 'reason',
    width: 150,
    minWidth: 150
  },
  {
    id: 'timestamp',
    header: 'Date/Time',
    cell: e => formatTimestamp(e.timestamp),
    ariaLabel: createLabelFunction('timestamp'),
    sortingField: 'timestamp',
    width: 200,
    minWidth: 170
  },
  {
    id: 'message',
    header: 'Q Message',
    cell: e => processMessage(e.message),
    ariaLabel: createLabelFunction('message'),
    sortingField: 'message',
    width: 200,
    minWidth: 170
  },
  {
    id: 'prevMessage',
    header: 'Previous Message',
    cell: e => processMessage(e.prevMessage),
    ariaLabel: createLabelFunction('prevMessage'),
    sortingField: 'prevMessage',
    width: 200,
    minWidth: 170
  }
];

export const paginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: pageNumber => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page',
};

const pageSizePreference = {
  title: 'Select page size',
  options: [
    { value: 10, label: '10 records' },
    { value: 20, label: '20 records' },
  ],
};

const contentDisplayPreference = {
  title: 'Column preferences',
  description: 'Customize the columns visibility and order.',
  options: columnDefinitions.map(({ id, header }) => ({ id, label: header, alwaysVisible: id === 'id' })),
};
export const collectionPreferencesProps = {
  pageSizePreference,
  contentDisplayPreference,
  cancelLabel: 'Cancel',
  confirmLabel: 'Confirm',
  title: 'Preferences',
};