import React, { useEffect, useRef, useState } from "react";
import ReactQuill from 'react-quill';
import axios from 'axios';
import {
  Container,
  ContentLayout,
  Form,
  SpaceBetween,
  Button,
  Header,
  TextContent
} from '@cloudscape-design/components';
import 'react-quill/dist/quill.snow.css';

// Set local variables from environment variables
const apiEndpoint = `${process.env.REACT_APP_ADMIN_BACKEND_API}/configurations`;

// Main component
const SiteConfigPage = ({ idToken, setAlertMessage, setAlertType, setToolsInformation }) => {
  const input_greetings = useRef(null);
  const input_info = useRef(null);
  const input_notfound = useRef(null);
  const input_prepend = useRef(null);
  const input_append = useRef(null);

  const [updateLoading, setUpdateLoading] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);

  useEffect(() => {
    // Set information content for this page
    setToolsInformation("<p>Please use this form to update the general site configuration for the user interface.</p> \
      <h4>Rich Text</h4> \
      <p>You may apply rich text formatting (using HTML tags) to the Greeting Message, Info Content, and NotFound Message fields. However, the Prepend Text and Append Text fields must remain in plain text.</p> \
      <h4>Name Placeholders</h4> \
      <p>The Greeting Message field supports two placeholders: <b>[fname]</b> and <b>[lname]</b>. If these placeholders are used, they will be replaced with the logged-in user's first name and last name, respectively.</p> \
      <h4>Optional Fields</h4> \
      <p>Prepend/Append Text fields are optional; if provided, their contents will be included in users' queries as specified.</p>");
    
    // Fetch configuration from DynamoDB
    FetchConfig();
  }, []);

  // Function to call API endpoint to fetch configuration
  const FetchConfig = async () => {
    try {
      const response = await axios.get(apiEndpoint, {
        params: {
          token: idToken
        }
      });

      input_greetings.current.getEditor().clipboard.dangerouslyPasteHTML(response.data.greeting_message);
      input_info.current.getEditor().clipboard.dangerouslyPasteHTML(response.data.info_message);
      input_notfound.current.getEditor().clipboard.dangerouslyPasteHTML(response.data.not_found_message);
      input_prepend.current.value = response.data.prompt_prepend;
      input_append.current.value = response.data.prompt_append;
    } catch (err) {
      setAlertType("error");
      setAlertMessage(`Unable to fetch feedback: ${err}`);
    } finally {
      setResetLoading(false);
    }
  }

  const handleConfigUpdate = () => {
    setUpdateLoading(true);
    let emptyFields = [];

    let inputGreetings = input_greetings.current.getEditor();
    let inputInfo = input_info.current.getEditor();
    let inputNotFound = input_notfound.current.getEditor();

    if (inputGreetings.getText().trim().length < 1) {
      emptyFields.push("Greeting Message");
    }

    if (inputInfo.getText().trim().length < 1) {
      emptyFields.push("Info Content");
    }

    if (inputNotFound.getText().trim().length < 1) {
      emptyFields.push("NotFound Text");
    }

    if (emptyFields.length > 0) {
      setAlertType("error");
      setAlertMessage(`Following fields are mandatory: [${emptyFields.join(", ")}]`);
      setUpdateLoading(false);
      return;
    }
    else 
    {
      const data = {
        greeting_message: inputGreetings.root.innerHTML,
        info_message: inputInfo.root.innerHTML,
        not_found_message: inputNotFound.root.innerHTML,
        prompt_prepend: input_prepend.current.value,
        prompt_append: input_append.current.value
      }

      axios.put(apiEndpoint, data, {
        params: {
          token: idToken
        }
      })
      .then(response => {
        if(response.status === 200) {
          setAlertType("success");
          setAlertMessage(`Configuration updated successfully`);
        } else {
          console.debug(`Received HTTP status ${response.status}`);
          setAlertType("error");
          setAlertMessage(`Error updating configuration`);
        }
      })
      .catch(error => {
        console.debug(error);
        setAlertType("error");
        setAlertMessage(`Error updating configuration`);
      })
      .finally(() => {
        setUpdateLoading(false);
      });
    }
  };

  const handleConfigReset = () => {
    setResetLoading(true);
    FetchConfig();
  };

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'font': [] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'align': [] }],
      [{ 'color': [] }, { 'background': [] }],
      ['blockquote', 'code-block'],
      ['link', 'image', 'video'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'indent': '-1'}, { 'indent': '+1' }],
      ['clean']
    ]
  };

  return (
    <ContentLayout header={<Header variant="h1">Site Configuration</Header>}>
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" loading={resetLoading} onClick={handleConfigReset}>Reset</Button>
            <Button variant="primary" loading={updateLoading} onClick={handleConfigUpdate}>Update</Button>
          </SpaceBetween>
        }
      >
        <Container>
          <SpaceBetween direction="vertical" size="xxl">

            <SpaceBetween direction="vertical" size="xs">
            <TextContent>
              <h3>Greeting Message</h3>
              <small>Users will be greeted with this message once they are connected</small>
            </TextContent>
            <ReactQuill ref={input_greetings} className="OT-quillEditor" modules={modules} />
            </SpaceBetween>

            <hr />

            <SpaceBetween direction="vertical" size="xs">
            <TextContent>
              <h3>Info Content</h3>
              <small>Add helpful information and guidance for the users. This will be visible when they click on the "Info" button</small>
            </TextContent>
            <ReactQuill ref={input_info} className="OT-quillEditor" modules={modules} />
            </SpaceBetween>

            <hr />

            <SpaceBetween direction="vertical" size="xs">
            <TextContent>
              <h3>NotFound Message</h3>
              <small>Set a custom text to replace Q Business's default "no answer found" message</small>
            </TextContent>
            <ReactQuill ref={input_notfound} className="OT-quillEditor" modules={modules} />
            </SpaceBetween>

            <hr />

            <SpaceBetween direction="vertical" size="xs">
            <TextContent>
              <h3>Prepend Text</h3>
              <small>[optional] This will be added before the users' questions</small>
            </TextContent>
            <textarea ref={input_prepend} className="OT-textEditor" rows="3" />
            </SpaceBetween>

            <hr />

            <SpaceBetween direction="vertical" size="xs">
            <TextContent>
              <h3>Append Text</h3>
              <small>[optional] This will be added after the users' questions</small>
            </TextContent>
            <textarea ref={input_append} className="OT-textEditor" rows="3" />
            </SpaceBetween>

          </SpaceBetween>
        </Container>
      </Form>
    </ContentLayout>
  );
};

export default SiteConfigPage;