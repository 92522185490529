import React from 'react';
import { useLocation } from 'react-router-dom';
import BreadcrumbGroup from '@cloudscape-design/components/breadcrumb-group';

const Breadcrumbs = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  const basePath = process.env.REACT_APP_BASE_PATH;

  let crumbChain = [{ text: "OriginTalk", href: `${basePath}/` }];

  if (currentPath === "/") {
    crumbChain.push({ text: "Home", href: `${basePath}/` });
  }
  else if (currentPath === "/config") 
  {
    crumbChain.push({ text: "Site Configuration", href: `${basePath}/config` });
  } 
  else if (currentPath === "/feedback") 
  {
    crumbChain.push({ text: "Feedbacks", href: `${basePath}/feedback` });
  }

  return (
    <BreadcrumbGroup
      items={crumbChain}
    />
  );
};

export default Breadcrumbs;