import React, { useEffect, useState } from "react";
import axios from 'axios';
import {
  Alert,
  Button,
  ColumnLayout,
  ContentLayout,
  Header,
  Link,
  SpaceBetween,
  TextContent
} from '@cloudscape-design/components';

// Set local variables from environment variables
const apiEndpoint = `${process.env.REACT_APP_ADMIN_BACKEND_API}/app_details`;

// Main component
const HomePage = ({ idToken, setAlertMessage, setAlertType, setToolsInformation }) => {
  
  // Set state variables
  const [qAppId, setQAppId] = useState(null);
  const [qAppRegion, setQAppRegion] = useState(null);
  const [cfnRegion, setCfnRegion] = useState(null);
  const [suffix, setSuffix] = useState(null);
  const [stage, setStage] = useState(null);

  useEffect(() => {
    // Set information content for this page
    setToolsInformation("<p>Welcome to the Admin Panel.</p> \
      <p>Use the left navigation pane to access various options and information. On this page, you will find the Q Business application ID currently used for the OriginTalk UI on the <em>Source</em> site. Additionally, this page provides links to access the built-in Analytics Dashboard, CloudWatch metrics, and CloudWatch Logs Insights for the backend Lambda functions, which are essential for performance measurement.</p> \
      <h4>Troubleshooting Broken URLs</h4> \
      <p>If you encounter issues with the displayed metrics, please verify that the Q Business app ID is correct. To update the app ID, modify the relevant CloudFormation stack.</p> \
      <p>Should the CloudWatch Logs Insights links fail to function, check the <code>.env</code> file for this admin panel. Ensure that the variables <code>REACT_APP_QBUSSINESS_APPID</code>, <code>REACT_APP_QBUSSINESS_REGION</code>, <code>REACT_APP_CLOUDFORMATION_STACK_SUFFIX</code>, and <code>REACT_APP_STAGE</code> are set correctly. These details can be found in the \"Output\" tab of the relevant CloudFormation stack. After updating these environment variables, you will need to rebuild and redeploy the admin panel.</p>");
  
    // Fetch Q app details from API GW
    FetchAppDetails();
  }, []);

  // Function to call API endpoint to fetch Q app details
  const FetchAppDetails = async () => {
    try {
      const response = await axios.get(apiEndpoint, {
        params: {
          token: idToken
        }
      });

      setQAppId(response.data.q_app_id);
      setQAppRegion(response.data.q_app_region);
      setCfnRegion(response.data.cfn_region);
      setSuffix(response.data.suffix);
      setStage(response.data.stage);
    } catch (err) {
      setAlertType("error");
      setAlertMessage(`Unable to fetch Q App details: ${err}`);
    }
  }

  // Construct URLs
  const generateAnalyticsDashboardLink = () => {
    return `https://${qAppRegion}.console.aws.amazon.com/amazonq/business/applications/${qAppId}/analytics-dashboard?region=${qAppRegion}`;
  };

  const generateCwMetricLink = (metricName, startTime = "PT168H", period = 86400) => {
    return `https://${qAppRegion}.console.aws.amazon.com/cloudwatch/home?region=${qAppRegion}#metricsV2?graph=~(view~'timeSeries~stacked~false~region~'${qAppRegion}~start~'-${startTime}~end~'P0D~stat~'Sum~period~${period}~metrics~(~(~'AWS*2fQBusiness~'${metricName}~'ApplicationId~'${qAppId})))&query=~'*7bAWS*2fQBusiness*2cApplicationId*7d`;
  };

  const generateLogInsightLink = (functionBaseName) => {
    return `https://${cfnRegion}.console.aws.amazon.com/cloudwatch/home?region=${cfnRegion}#logsV2:logs-insights$3FqueryDetail$3D~(end~0~start~-604800~timeType~'RELATIVE~tz~'LOCAL~unit~'seconds~editorString~'filter*20*40type*20*3d*20**201cREPORT**201d*20*0a*7c*20stats*20*0a*20count*28*40type*29*20as*20countInvocations*2c*0a*20count*28*40initDuration*29*20as*20countColdStarts*2c*0a*20*28count*28*40initDuration*29*2fcount*28*40type*29*29*2a100*20as*20percentageColdStarts*2c*0a*20max*28*40initDuration*29*20as*20maxColdStartTime*2c*0a*20avg*28*40duration*29*20as*20averageDuration*2c*0a*20max*28*40duration*29*20as*20maxDuration*2c*0a*20min*28*40duration*29*20as*20minDuration*2c*0a*20ceil*28avg*28*40maxMemoryUsed*29*2f1000000*29*20as*20averageMemoryUsed*2c*0a*20max*28*40memorySize*29*2f1000000*20as*20memoryAllocated*2c*0a*20*28avg*28*40maxMemoryUsed*29*2fmax*28*40memorySize*29*29*2a100*20as*20percentageMemoryUsed*20*0aby*20bin*281h*29*20as*20timeFrame~queryId~'6b17bab0-7273-4de6-8cb8-afac40a1ecae~source~(~'*2faws*2flambda*2f${functionBaseName}-${stage}-${suffix}))`;
  };

  return (
    <div>
      <ContentLayout header={<Header variant="h1">Admin Panel - Home</Header>}>
        <SpaceBetween size="xxl">
          <Alert statusIconAriaLabel="Info" header="Q Business Application ID" action={<Button href={generateAnalyticsDashboardLink()} iconAlign="right" iconName="external" target="_blank">Analytics Dashboard</Button>}>
            {qAppId}
          </Alert>

          <ColumnLayout columns={2}>
            <TextContent>
              <h3>Available metrics on Cloudwatch</h3>
              <ul>
                <SpaceBetween size="s">
                  <li><Link external href={generateCwMetricLink("BlockedChatMessages")} variant="secondary">BlockedChatMessages</Link></li>
                  <li><Link external href={generateCwMetricLink("ChatMessages")} variant="secondary">ChatMessages</Link></li>
                  <li><Link external href={generateCwMetricLink("ChatMessagesWithNoAnswer")} variant="secondary">ChatMessagesWithNoAnswer</Link></li>
                  <li><Link external href={generateCwMetricLink("DailyActiveUsers")} variant="secondary">DailyActiveUsers</Link></li>
                  <li><Link external href={generateCwMetricLink("MonthlyActiveUsers", "PT2160H", 2592000)} variant="secondary">MonthlyActiveUsers</Link></li>
                  <li><Link external href={generateCwMetricLink("NewConversations")} variant="secondary">NewConversations</Link></li>
                  <li><Link external href={generateCwMetricLink("ThumbsDownCount")} variant="secondary">ThumbsDownCount</Link></li>
                  <li><Link external href={generateCwMetricLink("ThumbsUpCount")} variant="secondary">ThumbsUpCount</Link></li>
                </SpaceBetween>
              </ul>
            </TextContent>

            <TextContent>
              <h3>Lambda Function Performance Logs</h3>
              <ul>
                <SpaceBetween size="s">
                  <li><Link external href={generateLogInsightLink("origin-talk-apigw-authorizer")} variant="secondary">apigw-authorizer</Link></li>
                  <li><Link external href={generateLogInsightLink("origin-talk-get-chat")} variant="secondary">get-chat</Link></li>
                  <li><Link external href={generateLogInsightLink("origin-talk-list-messages")} variant="secondary">list-messages</Link></li>
                  <li><Link external href={generateLogInsightLink("origin-talk-list-conversations")} variant="secondary">list-conversations</Link></li>
                  <li><Link external href={generateLogInsightLink("origin-talk-delete-conversation")} variant="secondary">delete-conversation</Link></li>
                  <li><Link external href={generateLogInsightLink("origin-talk-put-feedback")} variant="secondary">put-feedback</Link></li>
                  <li><Link external href={generateLogInsightLink("origin-talk-admin-panel-authorizer")} variant="secondary">admin-panel-authorizer</Link></li>
                </SpaceBetween>
              </ul>
            </TextContent>
          </ColumnLayout>
        </SpaceBetween>
      </ContentLayout>
    </div>
  );
};

export default HomePage;